import { requests } from './api-service';

const productApi = {
    createProduct: (productData) => requests.post('product/save', productData),
    updateProduct: (productData) => requests.put('product/save', productData),
    getCount: (search, category) => requests.get('product/count', setProductCountParams(search, category)),
    list: (pageNumber, search, category) => requests.get('product/list', setProductListParams(pageNumber, search, category)),
    archiveProduct: (productData) => requests.put('product/archive', productData),
    activateProduct: (productData) => requests.put('product/activate', productData),
    getProductOptions: () => requests.get('product/options'),
    getProductDetail: (id) => requests.get(`product/details/${id}`),
};

export const setProductCountParams = (search, category) => {
    const countParams = new URLSearchParams();
    if (search) countParams.append('search', search);
    if (category) countParams.append('category', category);
    return countParams;
};

export const setProductListParams = (pageNumber = 1, search = '', category = '') => {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
    const params = new URLSearchParams();
    params.append('page', pageNumber.toString());
    params.append('limit', limit.toString());
    if (search) params.append('search', search);
    if (category) params.append('category', category);
    return params;
};

export default productApi;
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from '@themesberg/react-bootstrap';
import { faPlusCircle, faEdit, faTrash, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { showOk, showError } from '../components/Alerts';
import { Button, Modal, Form, ButtonGroup, Card } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './styles.scss'
import roleApi from '../services/roles-service';

const AddRoleAndPermissions = (props) => {
  const { 
    roleAndPermissionsData,
    cancelEdit,
    refreshList
  } = props;

  const { saveRoleAndPermissions, updateRoleAndPermissions } = roleApi;
  const { Header, Dialog, Title, Body, Footer } = Modal;
  const [loading, setLoading] = useState(false);
  const [addedPermissions, setAddedPermissions] = useState([]);
  const [permissionName, setPermissionName] = useState('');
  const [permissionError, setPermissionError] = useState(false);
  const [editPermission, setEditPermission] = useState({ name: '', index: null });
  const [editPermissionError, setEditPermissionError] = useState(false);

  const roleAndPermissionSchema = Yup.object().shape({
    roleName: Yup.string().required('Role name is required'),
  });

  const formOptions = {
    resolver: yupResolver(roleAndPermissionSchema),
    mode: 'all',
    defaultValues: {
      roleName: roleAndPermissionsData?.name,
    }
  };

  const { register, handleSubmit, getValues, formState } = useForm(formOptions);

  const { errors } = formState;

  const handleClose = () => {
    cancelEdit(false);
  };

  const handleSavePermissionAndRole = () => {
    setLoading(true);

    const formData = new FormData();
    const roleName = getValues('roleName');

    formData.append('roleName', roleName);

    if(addedPermissions.length > 0){
      const permissionsJson = JSON.stringify(addedPermissions);
      formData.append('permissions', permissionsJson);
    }

    if(roleAndPermissionsData){
      formData.append('roleId', roleAndPermissionsData._id);

      updateRoleAndPermissions(formData)
        .then(() => {
          showOk('Roles & permissions updated');
          refreshList();
          cancelEdit(false);
          setLoading(false);
        })
        .catch((e) => {
          showError(e?.data?.error);
          setLoading(false);
        });
    }
    else
    {
      saveRoleAndPermissions(formData)
        .then(() => {
          showOk('Roles & permissions saved');
          refreshList();
          cancelEdit(false);
          setLoading(false);
        })
        .catch((e) => {
          showError(e?.data?.error);
          setLoading(false);
        });
    }
  }

  const handleAddPermission = () => {
    setPermissionError('');

    if(!permissionName){
      setPermissionError('Permission name is required');
      return;
    }

    if (addedPermissions.some(permission => permission.name.toLowerCase() === permissionName.toLowerCase())) {
      setPermissionError('Permission name already exists');
      return;
    }

    setAddedPermissions([...addedPermissions, { index: addedPermissions.length, name: permissionName }]);
  }

  const onPermissionNameChange = (e) => {
    setPermissionName(e.target.value || '');
  };

  const onChangeToEditPermission = (e) => {
    setEditPermission({ ...editPermission, name: e.target.value });
  };

  const handleDeletePermission = (indexToDelete, id) => {
    if(id){
      setAddedPermissions(prevPermissions =>
        prevPermissions.map(permission =>
          permission._id === id
            ? { ...permission, toDelete: true }
            : permission
        )
      );
    }
    else
    {
      setAddedPermissions(addedPermissions.filter((_, index) => index !== indexToDelete));
    }
  }

  const handleSetToEditPermission = (permission) => {
    setEditPermission({ name: permission.name, index: permission.index });
  };

  const handleSavePermission = () => {
    setEditPermissionError('');
    
    if(!editPermission.name){
      setEditPermissionError("Permission name is required");
      return;
    }

    setAddedPermissions(
      addedPermissions.map((permission, index) =>
        index === editPermission.index ? { ...permission, name: editPermission.name } : permission
      )
    );

    setEditPermission({ name: '', index: null }); // Reset edit state
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const extractToDeletePermissions = () => addedPermissions.filter(item => !item.toDelete)

  useEffect(() => {
    setPermissionName('')
  }, [addedPermissions])

  useEffect(() => {
    if(roleAndPermissionsData){
      const updatedPermissions = roleAndPermissionsData.permissions.map((permission, index) => ({
        ...permission,
        index: index
      }));

      setAddedPermissions(updatedPermissions);
    }
  },[roleAndPermissionsData, setAddedPermissions]);

  return (
    <Modal as={Dialog} backdropClassName="" centered show={true}>
      <Form onSubmit={handleSubmit(handleSavePermissionAndRole)} onKeyDown={handleKeyDown}>
        <Header>
          <Title className="h6">{`${roleAndPermissionsData ? 'Update' : 'Create'}`} - Role & Permissions</Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Header>
        <Body>
          <Row>
            <Col md={12} className="mb-4">
              <Form.Group id="role-name">
                  <Form.Label className="light-font">Role name</Form.Label>
                    <Form.Control
                        type="text"
                        name="roleName"
                        placeholder="Role name"
                        {...register('roleName')}
                    />
                    {errors.roleName?.message && (
                        <small className="invalid-fields">{errors.roleName.message}</small>
                    )}
              </Form.Group>
            </Col>
          </Row>
          {addedPermissions.length > 0 && (
            <Row>
              <Col md={12} className="mb-4">
                <Card border="light" className="shadow-sm permission-holder">
                  <Card.Body>
                  {extractToDeletePermissions().map((item, index) => (
                    <div key={index} className="d-flex align-items-center justify-content-between border-bottom border-light pb-3 mt-2 added-permissions">
                      {editPermission.index !== index && (
                        <>
                          <h6>{item.name}</h6>
                          <div>
                            <ButtonGroup size="sm" aria-label="Client Agreements Actions">
                              <Button variant="primary" onClick={() => handleSetToEditPermission(item)}><FontAwesomeIcon icon={faEdit} /></Button>
                              <Button variant="danger" onClick={() => handleDeletePermission(index, item._id)}><FontAwesomeIcon icon={faTrash} /></Button>
                            </ButtonGroup>
                          </div>
                        </>
                      )}
                      {editPermission.index === index && (
                        <>
                          <Form.Group id="toEditPermission">
                            <Form.Control
                              type="text"
                              name="toEditPermission"
                              placeholder="Permission name"
                              value={editPermission.name}
                              onChange={onChangeToEditPermission}
                            />
                            {editPermissionError && (
                                <small className="invalid-fields">{editPermissionError}</small>
                            )}
                          </Form.Group>
                          <div>
                            <ButtonGroup size="sm" aria-label="Client Agreements Actions">
                              <Button variant="success" onClick={handleSavePermission}><FontAwesomeIcon icon={faSave} /></Button>
                              <Button variant="danger" onClick={() => setEditPermission({ name: '', index: null })}><FontAwesomeIcon icon={faTimes} /></Button>
                            </ButtonGroup>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          <Row className="align-items-center mt-2 mb-2">
              <Col className="ms--2">
                <Form.Group id="permissionName">
                  <Form.Control
                      type="text"
                      name="permissionName"
                      placeholder="Permission name"
                      value={permissionName}
                      onChange={onPermissionNameChange}
                    />
                    {permissionError && (
                        <small className="invalid-fields">{permissionError}</small>
                    )}
                </Form.Group>
              </Col>
              <Col className="col-auto">
                <Button variant="tertiary" size="sm" onClick={() => handleAddPermission()}>
                  <FontAwesomeIcon icon={faPlusCircle} className="me-1" /> Add
                </Button>
              </Col>
            </Row>
        </Body>
        <Footer>
          <Button type="submit" disabled={loading} variant="secondary">
            Save
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default AddRoleAndPermissions;

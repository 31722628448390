import { requests } from './api-service';

const paymentTermApi = {
    createPaymentTerm: (paymentTermData) => requests.post('payment-term', paymentTermData),
    updatePaymentTerm: (paymentTermData) => requests.put('payment-term', paymentTermData),
    getCount: (search) => requests.get('payment-term/count', setPaymentTermCountParams(search)),
    list: (pageNumber, search) => requests.get('payment-terms', setPaymentTermListParams(pageNumber, search)),
    archivePaymentTerm: (paymentTermData) => requests.put('payment-term/archive', paymentTermData),
    activatePaymentTerm: (paymentTermData) => requests.put('payment-term/activate', paymentTermData),
    getPaymentTermNames: () => requests.get('payment-term/names'),
    getClientsWithTerms: () => requests.get('payment-term/clients/terms'),
    getClientPaymentTermsById: (clientId) => requests.get(`payment-terms/client/${clientId}`),
};

export const setPaymentTermCountParams = (search) => {
    const countParams = new URLSearchParams();
    if (search) countParams.append('search', search);
    return countParams;
};

export const setPaymentTermListParams = (pageNumber = 1, search = '') => {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', limit.toString());
    if (search) params.append('search', search);
    return params;
};

export default paymentTermApi;
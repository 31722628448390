import { requests } from './api-service';

const quotationApi = {
    createQuotation: (quotationData) => requests.post('quotation', quotationData),
    getCount: (status, search, filterStartDate, filterEndDate, expired) => 
        requests.get('quotation/count', setQuotationsCountParams(status, search, filterStartDate, filterEndDate, expired)),
    list: (pageNumber, status, search, filterStartDate, filterEndDate, expired) => 
        requests.get('quotations', setQuotationListParams(status, pageNumber, search, filterStartDate, filterEndDate, expired)),
    changeQuotationStatus: (quotationDetail) => requests.put('quotation/change/status', quotationDetail),
    downloadQuotation: (quotationDetail) => requests.put('quotation/download', quotationDetail),
    sendQuotationDocument: (data) => requests.put('quotation/send/document', data),
    cancelQuotation: (data) => requests.put('quotation/cancel', data),
};

export const setQuotationsCountParams = (status, search, filterStartDate, filterEndDate, expired) => {
    const countParams = new URLSearchParams();
    
    if (status) countParams.append('status', status);
    if (search) countParams.append('search', search);
    if (filterStartDate) countParams.append('startDate', filterStartDate);
    if (filterEndDate) countParams.append('endDate', filterEndDate);
    if (expired) countParams.append('expired', expired);
    return countParams;
};

export const setQuotationListParams = (status, pageNumber = 1, search = '', filterStartDate = '', filterEndDate = '', expired = false) => {
    const limit = process.env.REACT_APP_PAGINATION_LIMIT || '10';
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', limit.toString());
    if (status) params.append('status', status);
    if (search) params.append('search', search);
    if (filterStartDate) params.append('startDate', filterStartDate);
    if (filterEndDate) params.append('endDate', filterEndDate);
    if (expired) params.append('expired', expired);
    return params;
};

export default quotationApi;
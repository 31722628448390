import { faCalendar, faEdit, faEllipsisH, faEye, faPaperPlane, faSearch } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useEffect, useState } from "react";
import { Card, InputGroup, Form, Row, Col, Button, Table } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmQuotationDocumentDialog, showError, showOk } from "../components/Alerts";
import { useDispatch, useSelector } from "react-redux";
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import { DEFAULT_TIMZONE, defaultPageNumber, PENDING_CLIENT_SIGNATURE_QUOTATION, QUOTATION_LIST_AND_HISTORY_EDIT_QUOTATION, QUOTATION_LIST_AND_HISTORY_SEND_QUOTATION_TO_CLIENT } from "../utils/constants";
import quotationApi from '../services/quotation-service';
import { setQuotationCount } from "../actions/quotation";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { formatCurrency } from "../utils/formatter";
import PortalPagination from "../components/PortalPagination";
import SalesProductDetail from "./salesproductdetail";
import SalesQuotationPreview from "./salesquotationpreview";
import SalesQuotationHolder from "./salesquotationholder";
import { checkQuotationStatusChange } from "../utils/helpers";
import { hasPermission } from "../auth/UserPermissionTypes";

const SalesOrderDatatable = (props) => {
    const { 
        status,
        clientCollection,
        productCollection,
        clientWithPaymentTermsCollection,
        productCategoryCollection,
        refreshProductList
    } = props;

    const dispatch = useDispatch();
    const { getCount, list, downloadQuotation, sendQuotationDocument } = quotationApi;
    const quotationState = useSelector((state) => state.quotationState[status] || { count: 0, totalPages: 0 });
    const totalRecords = quotationState.count;
    const [selectedPage, setSelectedPage] = useState(defaultPageNumber);
    const loading = useSelector((state) => state.blanket.showAdminLoader);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDateFilter, setStartDateFilter] = useState();
    const [endDateFilter, setEndDateFilter] = useState();
    const [includeExpiredQuotation, setIncludeExpiredQuotation] = useState(false);
    const [quotationList, setQuotationList] = useState([]);
    const [selectedQuotation, setSelectedQuotation] = useState(null);
    const [viewMode, setViewMode] = useState('view');
    const [isReset, setIsReset] = useState(false);
    const [productDetailToView, setProductDetailToView] = useState(null);
    const [showProductDetail, setShowProductDetail] = useState(false);
    const [hasRenderedList, setHasRenderedList] = useState(false);

    const refreshQuotationList = useCallback(
        async (pageNumber = 1) => {
            // Fetch the latest count of quotations
            try {
                setHasRenderedList(false);
                const resCount = await getCount(status, searchTerm, startDateFilter, endDateFilter, includeExpiredQuotation);
                dispatch(setQuotationCount(status, resCount.totalCount));
    
                // Fetch the latest list of quotations
                const resList = await list(pageNumber, status, searchTerm, startDateFilter, endDateFilter, includeExpiredQuotation);
                setQuotationList(resList.quotations);
                setHasRenderedList(true);
                setSelectedPage(pageNumber); // Update the current page number
            } catch (error) {
                console.error(error);
            }
        },
        [status, searchTerm, startDateFilter, endDateFilter, includeExpiredQuotation, dispatch]
    );

    const onkeyPressSearch = (e) => {
        if (e.key === 'Enter') {
            refreshQuotationList();
        }
    };

    const onSearchChange = (e) => {
        setSearchTerm(e.target.value || '');
    };

    const handleStartDateChange = (date) => {
        if (endDateFilter && date.isAfter(endDateFilter)) {
          showError("Start date cannot be later than end date.");
          return;
        }
        setStartDateFilter(date);
    };

    const handleEndDateChange = (date) => {
        if (startDateFilter && date.isBefore(startDateFilter)) {
          showError("End date cannot be earlier than start date.");
          return;
        }
        setEndDateFilter(date);
    };


    const handleSearchQuotation = () => {
        if (startDateFilter && endDateFilter && moment(startDateFilter).isAfter(endDateFilter)) {
            showError("End date cannot be earlier than start date.");
            return;
        }
        refreshQuotationList(defaultPageNumber);
    };

    const handleReset = () => {
        setSearchTerm('');
        setStartDateFilter(null);
        setEndDateFilter(null);
        setIncludeExpiredQuotation(false);
        setIsReset(!isReset);
    };

    const handlePageEvent = (pageNumber) => {
        refreshQuotationList(pageNumber);
    };

    const handleCancelView = () => {
        setSelectedQuotation(null);
    };

    const handleSelectQuotation = (data, mode) => {
        setSelectedQuotation(data);
        setViewMode(mode);
    };

    const handleDownloadPdf = async (id) => {
        try {
          const formData = new FormData();
          formData.append('quotationId', id);
      
          // Send the PDF to the backend for upload
          const apiResponse = await downloadQuotation(formData);
      
          if (apiResponse) {
            // Create a link and open the file in a new tab
            const link = document.createElement('a');
            link.href = apiResponse.downloadUrl;
            link.target = '_blank'; // Open in a new tab
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        } catch (error) {
          console.error('Error generating or uploading PDF:', error);
        }
    };

    const handleSendQuotationDocument = async (quotationId) => {
        let text = 'Do you want to send this quotation document to client?';
        let confirmBtnText = 'Yes, send it!';
    
        var statusConfirm = await confirmQuotationDocumentDialog(text, confirmBtnText);
  
        if (statusConfirm) {
          let formData = new FormData();
          formData.append('quotationId', quotationId);
  
          sendQuotationDocument(formData)
              .then(() => {
                  showOk('Quotation document sent to client');
              })
              .catch((e) => {
                  console.log(e)
              });
        }
    };

    const TableRow = (quotation) => {
        const { displayName } = quotation.clientData;

        const {
            _id,
            quotationNumber,
            quotationDate,
            totalBaseAmount,
            totalMarkUpAmount,
            validDateUntil,
            issueDate,
            paymentTermData,
            shipmentType,
            status
        } = quotation;

        const validUntilDate = moment(validDateUntil).tz(DEFAULT_TIMZONE).format('MMM. D, YYYY');
        const paymentTermName = paymentTermData.name;
        const formattedQuotationDate = moment(quotationDate).tz(DEFAULT_TIMZONE).format('MMM. D, YYYY');

        const formatIssueDate = moment(issueDate).tz(DEFAULT_TIMZONE).format('MMM. D, YYYY');
  
        return (
            <>
                <tr>
                    <td>{quotationNumber}</td>
                    <td>{formattedQuotationDate}</td>
                    <td>{displayName || ''}</td>
                    <td>{formatCurrency(parseFloat(totalBaseAmount)) || 0.00}</td>
                    <td>{formatCurrency(parseFloat(totalMarkUpAmount)) || 0.00}</td>
                    <td>{formatIssueDate}</td>
                    <td>{validUntilDate}</td>
                    <td>{paymentTermName}</td>
                    <td>{shipmentType}</td>
                    <td>{status}</td>
                    <td>
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                <span className="icon icon-sm">
                                    <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="more-left-dropdown-menu">
                                <Dropdown.Item onClick={() => handleSelectQuotation(quotation, 'view')}>
                                    <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                                </Dropdown.Item>
                                {checkQuotationStatusChange(status) && hasPermission(QUOTATION_LIST_AND_HISTORY_EDIT_QUOTATION) && (
                                    <Dropdown.Item onClick={() => handleSelectQuotation(quotation, 'update')}>
                                        <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                                    </Dropdown.Item>
                                )}
                                {hasPermission(QUOTATION_LIST_AND_HISTORY_SEND_QUOTATION_TO_CLIENT) && status === PENDING_CLIENT_SIGNATURE_QUOTATION && (
                                    <Dropdown.Item onClick={() => handleSendQuotationDocument(quotation._id)}>
                                        <FontAwesomeIcon icon={faPaperPlane} className="me-2" /> Send
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
                {selectedQuotation?._id === _id && (
                    <tr>
                        <td colSpan={11}>
                            <SalesQuotationHolder 
                                hideEvent={handleCancelView} 
                                quotationData={selectedQuotation} 
                                clientCollection={clientCollection} 
                                productCollection={productCollection}
                                clientWithPaymentTermsCollection={clientWithPaymentTermsCollection}
                                viewMode={viewMode}
                                refreshList={refreshQuotationList}
                                productCategoryCollection={productCategoryCollection}
                                refreshProductList={refreshProductList}
                                handleProductDetailFromDatatable={handleShowProductDetail}
                                donwloadPdf={handleDownloadPdf}
                                sendQuotationDocument={handleSendQuotationDocument}
                            />
                            {selectedQuotation && (
                                <div className='element-to-print' id={`element-to-print_${_id}`}>
                                    <SalesQuotationPreview quotationData={selectedQuotation} productCollection={productCollection} />
                                </div>
                            )}
                            
                        </td>
                    </tr>
                )}
            </>
        );
    };

    const handleShowProductDetail = (productId) => {
        const productData = productCollection.find((product) => product._id === productId);
        setProductDetailToView(productData);
        setShowProductDetail(true);
    }

    const handleCancelProductDetail = () => {
        setProductDetailToView(null);
        setShowProductDetail(false);
    }

    // Fetch data on component mount or status change
    useEffect(() => {
        refreshQuotationList(selectedPage);
    }, [status, dispatch]);

    useEffect(() => {
        if(isReset){
            setTimeout(() => {
                refreshQuotationList();
            }, 300);
        }
    }, [isReset])

    return (
        <>
            {showProductDetail && (
                <SalesProductDetail
                    product={productDetailToView}
                    cancelEdit={handleCancelProductDetail} 
                />
            )}
        
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="pt-0">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-4 mb-md-0 search-block-width-400">
                            <p className="mb-0">Search and filter sales quotation</p>
                        </div>
                        <div className="btn-toolbar mb-2 mb-md-0"></div>
                    </div>
                    <div className="table-settings mb-4">
                        <Row className="justify-content-between align-items-center">
                            <Col md={4}>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        onKeyPress={onkeyPressSearch}
                                        value={searchTerm}
                                        onChange={onSearchChange}
                                        placeholder="Search"
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <Datetime
                                    timeFormat={false}
                                    onChange={handleStartDateChange}
                                    closeOnSelect={true}
                                    renderInput={(props, openCalendar) => (
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faCalendar} />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                name="startDateFilter"
                                                value={startDateFilter ? moment(startDateFilter).format('MM/DD/YYYY') : ''}
                                                placeholder="Filter start date"
                                                onFocus={openCalendar}
                                                onChange={(e) => setStartDateFilter(e.target.value)}
                                            />
                                        </InputGroup>
                                    )}
                                />
                            </Col>
                            <Col md={4}>
                                <Datetime
                                    timeFormat={false}
                                    onChange={handleEndDateChange}
                                    closeOnSelect={true}
                                    renderInput={(props, openCalendar) => (
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faCalendar} />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                name="endDateFilter"
                                                value={endDateFilter ? moment(endDateFilter).format('MM/DD/YYYY') : ''}
                                                placeholder="Filter end date"
                                                onFocus={openCalendar}
                                                onChange={(e) => setEndDateFilter(e.target.value)}
                                                disabled={!startDateFilter}
                                            />
                                        </InputGroup>
                                    )}
                                />
                            </Col>
                            {/* <Col md={3}>
                                <Form.Group id="include-expired-quotation">
                                    <Form.Check
                                        label="Include Expired Quotation"
                                        defaultChecked={includeExpiredQuotation}
                                        id="includeExpiredQuotationCheckBox"
                                        htmlFor="includeExpiredQuotationCheckBox"
                                        onChange={(e) => onCheckIncludeExpiredQuotation(e)}
                                    />
                                </Form.Group>
                            </Col> */}
                        </Row>
                        <Row className="justify-content-between align-items-center mt-3">
                            <Col md={3}>
                                <Button variant="primary" type="button" onClick={() => handleSearchQuotation()}>
                                    Search
                                </Button>
                                <Button className='ms-1' variant="secondary" type="button" onClick={() => handleReset()}>
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <Table hover className="billing-table-list align-items-center mt-3">
                        <thead>
                            <tr>
                                <th className="border-bottom">Quotation No.</th>
                                <th className="border-bottom">Quotation Date</th>
                                <th className="border-bottom">Client Name</th>
                                <th className="border-bottom">Total Amount</th>
                                <th className="border-bottom">Total Mark-up Amount</th>
                                <th className="border-bottom">Issue Date</th>
                                <th className="border-bottom">Valid Until</th>
                                <th className="border-bottom">Payment Term</th>
                                <th className="border-bottom">Shipment Type</th>
                                <th className="border-bottom">Status</th>
                                <th className="border-bottom"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {quotationList && quotationList.map((t, i) => <TableRow key={`quotation-list-${i}`} {...t} />)}
                            {hasRenderedList && quotationList && quotationList.length === 0 && (
                                <tr className="no-record"><td colSpan={11}>* No records found *</td></tr>
                            )}
                            {!hasRenderedList && (
                                <tr className="no-record"><td colSpan={11}>Fetching Records. Please wait.</td></tr>
                            )}
                        </tbody>
                    </Table>
                    {!loading && hasRenderedList && (
                        <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={handlePageEvent} />
                    )}
                </Card.Body>
            </Card>
        </>
    );
}

export default SalesOrderDatatable;

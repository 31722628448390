import React, { useCallback, useEffect, useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ProductUploadPhoto } from "./productuploadphoto";
import { showError, showOk } from "../components/Alerts";
import productApi from '../services/product-service';
import productResourceApi from '../services/product-resource-service';
import { hasPermission } from "../auth/UserPermissionTypes";
import { PRODUCT_MANAGER_MANAGE_PRODUCT_RESOURCE } from "../utils/constants";

const ProductForm = (props) => {
    const { productCategories, product, cancelEdit, refreshList } = props;

    const formSchema = Yup.object().shape({
        productName: Yup.string().required('Product name is required'),
        productCategory: Yup.string().required('Product category is required'),
        productColor: Yup.string().required('Product color is required'),
        productSize: Yup.string().required('Product size is required'),
        productPrice: Yup.string().required('Product price is required'),
        productMarkUpPercent: Yup.string().required('Product mark-up is required'),
        productModel: Yup.string().required('Product model is required'),
    });

    const formOptions = {
        resolver: yupResolver(formSchema),
        mode: 'all',
        defaultValues: {
            productName: product?.name,
            productCategory: product?.productCategoryData?._id || '',
            productColor: product?.color,
            productSize: product?.size,
            productPrice: product?.price,
            productMarkUpPercent: product?.markUpPrice,
            productDescription: product?.description,
            productModel: product?.model,
        },
    };

    const { createProduct, updateProduct } = productApi;
    const { productResources } = productResourceApi;

    const [loading, setLoading] = useState(false);
    const [resources, setResources] = useState([]);
    const [primarySequence, setPrimarySequence] = useState(null);

    const { register, handleSubmit, getValues, formState, reset, setValue } = useForm(formOptions);
    const { errors } = formState;

    const generateLabel = (label, fieldName) => {
        const isRequired = formSchema.fields[fieldName];
        return (
            <Form.Label className="light-font">
                {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
            </Form.Label>
        );
    };

    const handleProductNumberChange = (e, rateProp) => {
        const value = e.target.value;
        if (value === '') {
            setValue(rateProp, null);
        } else if (!isNaN(value) && parseFloat(value) >= 0) {
            setValue(rateProp, parseFloat(value));
        } else {
            setValue(rateProp, '');
        }
    };

    const onSubmit = () => {
        setLoading(true);
    
        let productData = new FormData();
        var formValues = getValues();
    
        for (const key in formValues) {
          if (key === 'field') {
            let formValue = formValues[key][1];
            productData.append(key, formValue);
          } else {
            let formValue = formValues[key];
    
            productData.append(key, formValue);
          }
        }
    
        if (product) {
            productData.append('id', product._id);
            updateProduct(productData)
            .then((data) => {
                if (data.error) {
                    showError(data.error);
                    setLoading(false);
                } else {
                    showOk('Product information saved');
                    cancelEdit();
                    setLoading(false);
                    refreshList();
                }
            })
            .catch((e) => {
                showError(e?.data?.error);
                setLoading(false);
            });
        } else {
            createProduct(productData)
            .then((data) => {
                if (data.error) {
                    showError(data.error);
                    setLoading(false);
                } else {
                    showOk('Product created');
                    setLoading(false);
                    cancelEdit();
                    reset();
                    if(refreshList){
                        refreshList();
                    }
                    
                }
            })
            .catch((e) => {
                showError(e?.data?.error);
                setLoading(false);
            });
        }
    };

    const fetchResources = useCallback(
        async (productId, isActive = true) => {
            if(!hasPermission(PRODUCT_MANAGER_MANAGE_PRODUCT_RESOURCE)) return;

            try {
                if (productId) {
                    const result = await productResources(productId, isActive);
                    setResources(result.data);

                    // Set the initial primary sequence based on fetched resources
                    const primaryResource = result.data.find(resource => resource.isPrimary);
                    if (primaryResource) {
                        setPrimarySequence(primaryResource.sequence);
                    }
                }
            } 
            catch (error) {
                console.error('Error while fetching product resources:', error);
            }
    }, [productResources]);

    const handleCancelEdit = () => {
        reset();
        cancelEdit(true);
    }

    const getResourceBySequence = (sequence) => {
        if (!resources) return null;
        return resources.find(resource => resource.sequence === sequence) || null;
    };

    const handlePrimaryChange = (sequence) => {
        setPrimarySequence(sequence);
    };

    useEffect(() => {
        if (product) {
            fetchResources(product._id);
        }
    }, [product, fetchResources]);

    return (
        <Card border="light" className="table-wrapper table-responsive shadow-sm no-border-radius">
            <Card.Body className="pt-0">
                <h3 className="mb-3 border-bottom border-light mt-3 pb-md-3 mb-md-3">{!product ? 'Add' : 'Update'} - Product</h3>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className='align-items-start'>
                        <Col md={4} className="mb-3">
                            <Form.Group id="productCategory">
                                {generateLabel('Category', 'productCategory')}
                                <Form.Select name="productCategory" defaultValue="" {...register('productCategory')}>
                                    <option value="">Select Category</option>
                                    {productCategories.map((item, i) => (
                                        <option key={i} value={item._id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </Form.Select>
                                {errors.productCategory?.message && <small className="invalid-fields">{errors.productCategory.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group id="productName">
                                {generateLabel('Name', 'productName')}
                                <Form.Control 
                                    type="text" 
                                    name="productName" 
                                    placeholder="Name"
                                {...register('productName')} />
                                {errors.productName?.message && <small className="invalid-fields">{errors.productName.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group id="productModel">
                                {generateLabel('Model', 'productModel')}
                                <Form.Control 
                                    type="text" 
                                    name="productModel" 
                                    placeholder="Model"
                                {...register('productModel')} />
                                {errors.productModel?.message && <small className="invalid-fields">{errors.productModel.message}</small>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='align-items-start'>
                        <Col md={4} className="mb-3">
                            <Form.Group id="productColor">
                                {generateLabel('Color', 'productColor')}
                                <Form.Control 
                                    type="text" 
                                    name="productColor" 
                                    placeholder="Color"
                                {...register('productColor')} />
                                {errors.productColor?.message && <small className="invalid-fields">{errors.productColor.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group id="productSize">
                                {generateLabel('Size', 'productSize')}
                                <Form.Control 
                                    type="text" 
                                    name="productSize" 
                                    placeholder="Size"
                                {...register('productSize')} />
                            </Form.Group>
                        </Col>
                        <Col md={4} className="mb-3">
                            <Form.Group id="productPrice">
                                {generateLabel('Price', 'productPrice')}
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    name="productPrice"
                                    placeholder="0"
                                    {...register('productPrice', { onChange: (e) => handleProductNumberChange(e, 'productPrice') })}
                                    min="0"
                                />
                                {errors.productPrice?.message && <small className="invalid-fields">{errors.productPrice.message}</small>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='align-items-start'>
                        <Col md={4} className="mb-3">
                            <Form.Group id="productMarkUpPercent">
                                {generateLabel('Mark-up Percent', 'productMarkUpPercent')}
                                <Form.Control
                                    type="number"
                                    step="0.01"
                                    name="productMarkUpPercent"
                                    placeholder="0"
                                    {...register('productMarkUpPercent', { onChange: (e) => handleProductNumberChange(e, 'productMarkUpPercent') })}
                                    min="0"
                                />
                                {errors.productMarkUpPercent?.message && <small className="invalid-fields">{errors.productMarkUpPercent.message}</small>}
                            </Form.Group>
                        </Col>
                        <Col md={8} className="mb-3">
                            <Form.Group id="productDescription">
                                <Form.Label className="light-font">Description</Form.Label>
                                <Form.Control as="textarea" placeholder='Product description' {...register('productDescription')} rows="1" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="mt-3">
                        <Button variant="primary" type="submit" className="me-1" disabled={loading}>
                            Save
                        </Button>
                        <Button variant="primary" type="button" onClick={() => handleCancelEdit()}>
                            Cancel
                        </Button>
                    </div>
                    {hasPermission(PRODUCT_MANAGER_MANAGE_PRODUCT_RESOURCE) && product && (
                        <>
                            <h3 className="mb-4 border-bottom border-light mt-5 pb-md-3 mb-md-5">Upload Photos</h3>
                            <Row className="align-items-start">
                                {[1, 2, 3, 4].map((sequence) => (
                                    <Col md={3} className="mb-3" key={sequence}>
                                        <ProductUploadPhoto 
                                            sequence={sequence} 
                                            productId={product._id} 
                                            data={getResourceBySequence(sequence)} 
                                            refreshList={refreshList} 
                                            isPrimary={primarySequence === sequence}
                                            onPrimaryChange={handlePrimaryChange}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </>
                    )}

                </Form>
            </Card.Body>
        </Card>
    )
}

export default ProductForm;
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCalendar, faSearch, faTruck } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Table, InputGroup, Form, Row, Col } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCancelledBillingCount } from '../actions/billingdocument';
import { defaultPageNumber } from '../utils/constants';
import billingApi from '../services/billing-service';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import { formatCurrency, truncateDescription } from "../utils/formatter";
import PortalPagination from '../components/PortalPagination';
import './styles.scss'
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import { showError } from '../components/Alerts';
import BillingHolder from './billingholder';

const CancelledBillings = (props) => {
    const { search } = props;
    const dispatch = useDispatch();
    const { getCancelledCount, getCancelledList } = billingApi;
    const totalRecords = useSelector((state) => state.billingDocumentState.cancelledCount) || 0;
    const [selectedBillingDocument, setSelectedBillingDocument] = useState(null);
    const [cancelledList, setCancelledList] = useState([]);
    const [selectedPage, setSelectedPage] = useState(defaultPageNumber);
    const loading = useSelector((state) => state.blanket.showAdminLoader);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDateFilter, setStartDateFilter] = useState();
    const [endDateFilter, setEndDateFilter] = useState();
    const [searchContainerNumber, setSearchContainerNumber] = useState('');
    const [resetTriggered, setResetTriggered] = useState(false);

    const handleSelecteBillingDocument = (id) => {
        setSelectedBillingDocument(id);
    }

    const handlePageEvent = (pageNumber) => {
      handleFetchCancelledBillings(pageNumber, search);
    };

    const handleReset = () => {
      setSearchTerm('');
      setStartDateFilter(null);
      setEndDateFilter(null);
      setSearchContainerNumber('');
      setResetTriggered(true); 
    };

    const onSearchChange = (e) => {
      setSearchTerm(e.target.value || '');
    };

    const onkeyPressSearch = (e) => {
      if (e.key === 'Enter') {
        handleFetchCancelledBillings();
      }
    };

    const onFilterContainerChange = (e) => {
      setSearchContainerNumber(e.target.value || '');
    };

    const handleSearchDocument = () => {
      if (startDateFilter && endDateFilter && moment(startDateFilter).isAfter(endDateFilter)) {
        showError("End date cannot be earlier than start date.")
        return;
      }

      handleFetchCancelledBillings(defaultPageNumber);
    }
    
    const handleFetchCancelledBillings = async (pageNumber = 1) => {
      setSelectedPage(pageNumber);

      try {
        const countRes = await getCancelledCount(searchTerm, startDateFilter, endDateFilter, searchContainerNumber);

        dispatch(setCancelledBillingCount(countRes.totalCount));

        const listRes = await getCancelledList(pageNumber, searchTerm, startDateFilter, endDateFilter, searchContainerNumber);

        setCancelledList(listRes.results);
      } catch (error) {
        console.error('Error while searching cancelled list:', error);
      }
    }

    const handleCancelView = () => {
        setSelectedBillingDocument(null);
    }

    const handleStartDateChange = (date) => {
      if (endDateFilter && date.isAfter(endDateFilter)) {
        showError("Start date cannot be later than end date.")
        return;
      }
      setStartDateFilter(date);
    };
  
    const handleEndDateChange = (date) => {
      if (startDateFilter && date.isBefore(startDateFilter)) {
        showError("End date cannot be earlier than start date.")
        return;
      }
      setEndDateFilter(date);
    };

    useEffect(() => {
      getCancelledCount(searchTerm, startDateFilter, endDateFilter).then((res) => {
          dispatch(setCancelledBillingCount(res.totalCount));
          getCancelledList(1, searchTerm, startDateFilter, endDateFilter).then((res) => {
            setCancelledList(res.results);
          });
        });
    }, [getCancelledCount, dispatch, getCancelledList]);

    useEffect(() => {
      handleFetchCancelledBillings(defaultPageNumber);
      setResetTriggered(false);
    }, [resetTriggered]);

    const TableRow = (document) => {
      const { displayName } = document.clientData;
      const { 
          _id,
          sumOfCBM, 
          sumOfSensitiveCharge, 
          sumOfForklift, 
          sumOfInland, 
          sumOfGrossWeight,
          grossAmount,
          totalBillableAmount,
          containerNumber,
          loadingDate
      } = document;

        const cancelReason = document?.generatedBillingData?.cancelReason || '';

        let rate = 0;

        if(document.appliedRateData) rate = document.appliedRateData.rate;

        if (document.appliedClientSpecialRateData && Object.keys(document.appliedClientSpecialRateData).length !== 0) {
        const { fixRate, bracketRate } = document.appliedClientSpecialRateData;
        rate = parseFloat(fixRate) > 0 ? parseFloat(fixRate) : parseFloat(bracketRate);
      }
  
      return (
        <>
          <tr>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    displayName || ''
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    containerNumber || ''
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    moment(loadingDate).format('MMM. DD, YYYY') || ''
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(sumOfCBM)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(sumOfSensitiveCharge)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(sumOfForklift)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(sumOfInland)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(sumOfGrossWeight)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(rate)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(grossAmount)) || 0.00
                  )}
              </span>
            </td>
            <td>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                    <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                    formatCurrency(parseFloat(totalBillableAmount)) || 0.00
                  )}
              </span>
            </td>
            <td>
              {loading && selectedBillingDocument == null ? (
                  <Skeleton wrapper={SkeletonWrapper} />
              ) : (
                  <span className={'fw-normal text-danger'}>CANCELLED</span>
              )}
            </td>
            <td title={cancelReason.length > 10 ? cancelReason : ''}>
              <span className="fw-normal">
                  {loading && selectedBillingDocument == null ? (
                  <Skeleton wrapper={SkeletonWrapper} />
                  ) : (
                  <div className="truncated-text" title={cancelReason}>
                      {truncateDescription(cancelReason, 10)}
                  </div>
                  )}
              </span>
          </td>
            <td>
              <Button variant="primary" title='View Details' size="sm" className="me-2" onClick={() => handleSelecteBillingDocument(_id)}>
                  <FontAwesomeIcon icon={faEye} className="icon-dark" />
              </Button>
            </td>
          </tr>
          {selectedBillingDocument === _id && (
            <tr>
              <td colSpan={12}>
                <BillingHolder hideEvent={handleCancelView} billingDocumentData={document} />
              </td>
            </tr>
          )}
        </>
      );
    };

    return (
        <Card border="light" className="table-wrapper table-responsive shadow-sm">
            <Card.Body className="pt-0">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0 search-block-width-400">
                  <p className="mb-0">Search and filter cancelled billings</p>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0"></div>
              </div>
              <div className="table-settings mb-4">
                <Row className="justify-content-between align-items-center">
                  <Col md={3}>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        onKeyPress={onkeyPressSearch}
                        value={searchTerm}
                        onChange={onSearchChange}
                        placeholder="Search"
                      />
                    </InputGroup>
                  </Col>
                  <Col md={3}>
                    <Datetime
                      timeFormat={false}
                      onChange={handleStartDateChange}
                      closeOnSelect={true}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendar} />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            name="startDateFilter"
                            value={startDateFilter ? moment(startDateFilter).format('MM/DD/YYYY') : ''}
                            placeholder="Filter start date"
                            onFocus={openCalendar}
                            onChange={(e) => setStartDateFilter(e.target.value)}
                          />
                        </InputGroup>
                      )}
                    />
                  </Col>
                  <Col md={3}>
                    <Datetime
                      timeFormat={false}
                      onChange={handleEndDateChange}
                      closeOnSelect={true}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendar} />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            name="endDateFilter"
                            value={endDateFilter ? moment(endDateFilter).format('MM/DD/YYYY') : ''}
                            placeholder="Filter end date"
                            onFocus={openCalendar}
                            onChange={(e) => setEndDateFilter(e.target.value)}
                            disabled={!startDateFilter}
                          />
                        </InputGroup>
                      )}
                    />
                  </Col>
                  <Col md={3}>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faTruck} />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        onKeyPress={onkeyPressSearch}
                        value={searchContainerNumber}
                        onChange={onFilterContainerChange}
                        placeholder="Filter container number"
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="justify-content-between align-items-center mt-3">
                  <Col md={3}>
                    <Button variant="primary" type="button" onClick={() => handleSearchDocument()}>
                      Search
                    </Button>
                    <Button className='ms-1' variant="secondary" type="button" onClick={() => handleReset()}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </div>
              <Table hover className="billing-table-list align-items-center mt-3">
                  <thead>
                      <tr>
                          <th className="border-bottom">Display Name</th>
                          <th className="border-bottom">Tracking Number</th>
                          <th className="border-bottom">Loading Date</th>
                          <th className="border-bottom">Sum of CBM</th>
                          <th className="border-bottom">Sum of Sensitive Charge</th>
                          <th className="border-bottom">Sum of Forklift</th>
                          <th className="border-bottom">Sum of Inland</th>
                          <th className="border-bottom">Sum of Gross Wt</th>
                          <th className="border-bottom">Rate</th>
                          <th className="border-bottom">Freight Amount</th>
                          <th className="border-bottom">Billable Amount</th>
                          <th className="border-bottom">Status</th>
                          <th className="border-bottom">Reason</th>
                          <th className="border-bottom"></th>
                      </tr>
                  </thead>
                  <tbody>
                      {cancelledList && cancelledList.map((t, i) => <TableRow key={`cancelled-billing-${i}`} {...t} />)}
                      {cancelledList.length === 0 && (
                        <tr className="no-record"><td colSpan={11}>* No records found *</td></tr>
                      )}
                  </tbody>
              </Table>
              {!loading && (
                  <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={handlePageEvent} />
              )}
            </Card.Body>
        </Card>
    )
}

export default CancelledBillings;
import React, { Fragment } from "react";
import { Table, Card, Row, Col,Image } from "react-bootstrap";
import SeaRoadBrand from '../assets/img/brand/sea-road-dark.png';
import moment from "moment-timezone";
import { formatCurrency } from "../utils/formatter";

const TransactionPerLoadingSummaryPreview = (props) => {
  const { reportData, startDateFilter, endDateFilter } = props;

  // Convert start and end date filters to moment objects
  const startMoment = moment(startDateFilter);
  const endMoment = moment(endDateFilter);

  const formattedStartDate = startDateFilter ? moment(startDateFilter).format('YYYY-MM-DD') : '';
  const formattedEndDate = endDateFilter ? moment(endDateFilter).format('YYYY-MM-DD') : '';

  // Generate months based on the range from start to end filters
  const filteredMonths = [];
  let currentMonth = startMoment.clone();
  while (currentMonth.isSameOrBefore(endMoment, "month")) {
    filteredMonths.push({
      month: currentMonth.month() + 1,
      label: currentMonth.format("MMMM"),
    });
    currentMonth.add(1, "month");
  }

  // Extract unique years from the filtered report data
  const years = [...new Set(reportData.map((item) => moment(item.loadingDate).year()))];

  // Group by year and month
  const groupByMonthAndContainer = (list, year, month) => {
    const filteredList = list.filter((item) => {
      const itemDate = moment(item.loadingDate);
      return (
        itemDate.year() === year &&
        itemDate.month() + 1 === month &&
        (!startDateFilter || itemDate.isSameOrAfter(moment(startDateFilter))) &&
        (!endDateFilter || itemDate.isSameOrBefore(moment(endDateFilter)))
      );
    });

    const grouped = [...new Set(filteredList.map((item) => item.containerNumber))].map((containerNumber) => ({
      containerNumber,
    }));

    return {
      hasResult: filteredList.length > 0,
      grouped,
    };
  };

  return (
    <Card className="bg-white shadow-sm mb-4" style={{width: '100%'}}>
      <Card.Body>
        <Row>
            <Col md="12">
                <div className="preview-container">
                    <div className="preview-header">
                        <Image src={SeaRoadBrand} className="navbar-brand-light" alt="SEA ROAD TRADING INC." />
                        <div className="company-info">
                            <h2>SEA ROAD TRADING INC.</h2>
                            <p>Gochan Compound, Warehouse 5B, MJ Cuenco Avenue, Mabolo, Cebu City, Philippines</p>
                            <p>Telephone No.: (032) 434-6453 | TIN#: 274-454-701-000</p>
                        </div>
                    </div>
                    <div className="border-line-up"></div>
                    <div className="border-line-down"></div>
                </div>
            </Col>
        </Row>
        <div className="preview-body clearfix mb-5">
            <h1>Transactions Per Loading Summary Report</h1>
            <div className="left">
                <p>Start Date: <b>{formattedStartDate}</b></p>
                <p>End Date: <b>{formattedEndDate}</b></p>
            </div>
        </div>
        {years.map((year, yearIndex) => (
          <Fragment key={yearIndex}>
            <Table className="main-tbl-summary mt-5">
              {filteredMonths.map((monthObj, index) => {
                const { hasResult, grouped } = groupByMonthAndContainer(reportData, year, monthObj.month);

                return (
                  <Fragment key={index}>
                    <thead>
                      <tr>
                        <th className="border-bottom month-indicator">
                          <h5>
                            {monthObj.label} {year}
                          </h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-bottom">
                          {hasResult ? (
                            <Table hover className="billing-table-list align-items-center">
                              <thead>
                                <tr>
                                  <th className="border-bottom">Container Number</th>
                                  <th className="border-bottom">Total CBM</th>
                                  <th className="border-bottom">Total Gross Amount</th>
                                  <th className="border-bottom">Total Freight Amount</th>
                                  <th className="border-bottom">Total Billable Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {grouped.map((entry, index) => {
                                  const filteredItems = reportData.filter(
                                    (item) =>
                                      moment(item.loadingDate).year() === year &&
                                      moment(item.loadingDate).month() + 1 === monthObj.month &&
                                      item.containerNumber === entry.containerNumber
                                  );

                                  const totalCBM = filteredItems.reduce((sum, item) => sum + parseFloat(item.sumOfCBM), 0).toFixed(2);
                                  const totalGrossAmount = filteredItems.reduce((sum, item) => sum + parseFloat(item.grossAmount), 0).toFixed(2);
                                  const totalFreightAmount = filteredItems.reduce((sum, item) => sum + parseFloat(item.totalFreightAmount), 0).toFixed(2);
                                  const totalBillableAmount = filteredItems.reduce((sum, item) => sum + parseFloat(item.totalBillableAmount), 0).toFixed(2);

                                  return (
                                    <tr key={index}>
                                      <td>{entry.containerNumber}</td>
                                      <td>{totalCBM}</td>
                                      <td>{formatCurrency(parseFloat(totalGrossAmount))}</td>
                                      <td>{formatCurrency(parseFloat(totalFreightAmount))}</td>
                                      <td>{formatCurrency(parseFloat(totalBillableAmount))}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          ) : (
                            <div>*** No Records Found ***</div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Fragment>
                );
              })}
            </Table>
          </Fragment>
        ))}
      </Card.Body>
    </Card>
  );
};

export default TransactionPerLoadingSummaryPreview;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPortalPage } from '../actions/blanket';
import { Card, Button, Table, Row, Col, Form, InputGroup, ButtonGroup } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faFileDownload, faFileAlt, faListAlt } from "@fortawesome/free-solid-svg-icons";
import reportApi from '../services/report-service';
import { setTransactionsPerLoadingReportCount } from '../actions/reports';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from '../components/TableRowWrapper';
import './styles.scss';
import { formatCurrency } from "../utils/formatter";
import PortalPagination from "../components/PortalPagination";
import { generatePdfReportAndDownload } from '../pdfGenerator';
import TransactionsPerLoadingPreview from "./transactionsperloadingpreview";
import TransactionPerLoadingSummary from "./transactionsperloadingsummary";
import TransactionPerLoadingSummaryPreview from "./transactionsperloadingsummarypreview";

const TransactionsPerLoading = () => {
    const dispatch = useDispatch();
    const { getTransactionsPerLoadingCount, getTransactionsPerLoadingList, getAllTransactionsPerLoadingList } = reportApi;
    const loading = useSelector((state) => state.blanket.showAdminLoader);
    const totalRecords = useSelector((state) => state.reportState.transactionPerLoadingReportCount) || 0;

    const defaultStartDate = moment().startOf('month').toDate();
    const defaultEndDate = moment().endOf('month').toDate();

    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [reportList, setReportList] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [allReportList, setAllReportList] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [summaryReport, setSummaryReport] = useState([]);
    const [reportView, setReportView] = useState('list');

    const formSchema = Yup.object().shape({
        containerNumber: Yup.string(),
        startDate: Yup.string().nullable().required('Start date is required')
            .test('start-date-test', 'Start date cannot be greater than end date', function(value) {
                const { endDate } = this.parent;
                return !value || !endDate || moment(value).isSameOrBefore(moment(endDate));
            }),
        endDate: Yup.string().nullable(),
    });

    const formOptions = {
        resolver: yupResolver(formSchema),
        mode: 'all',
        defaultValues: {
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        }
    };

    const { register, getValues, trigger, formState, setValue, reset } = useForm(formOptions);
    const { errors } = formState;

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setValue('startDate', date, { shouldValidate: true });
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setValue('endDate', date, { shouldValidate: true });
    };

    const resetFilters = () => {
        reset({
            containerNumber: '',
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        });

        setValue('containerNumber', '', { shouldValidate: true });
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setSelectedPage(1);
        dispatch(setTransactionsPerLoadingReportCount(0));
        setReportList([]);
        setAllReportList([]);
        setSummaryReport([]);
        setReportView('list')
    };

    const handlePageEvent = (pageNumber) => {
        handleGenerateReport(pageNumber);
    };

    const handleGenerateReport = async (pageNumber = 1) => {
        try {
            const isValid = await trigger();

            if (!isValid) return;

            setSelectedPage(pageNumber);

            setReportView('list');

            const { containerNumber } = getValues();
            
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : undefined;

            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : undefined;

            const countResponse = await getTransactionsPerLoadingCount(containerNumber, formattedStartDate, formattedEndDate);

            dispatch(setTransactionsPerLoadingReportCount(countResponse.count));

            const listResponse = await getTransactionsPerLoadingList(pageNumber, containerNumber, formattedStartDate, formattedEndDate);

            setReportList(listResponse);

        } catch (error) {
            console.error("Error while generating transactions per loading report:", error);
        }
    };

    const handleDownloadReport = async () => {
        try {
            setDownloading(true);
            const { containerNumber } = getValues();
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : undefined;
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : undefined;

            const listResponse = await getAllTransactionsPerLoadingList(containerNumber, formattedStartDate, formattedEndDate);
            
            setAllReportList(listResponse.data);

            setTimeout(() => {
                handleDownloadPdf();
            }, 100);
        } catch (error) {
            console.error("Error while generating transactions per loading report:", error);
        }
    };

    const handleDownloadSummaryReport = async () => {
        try {
            const { containerNumber } = getValues();
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : undefined;
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : undefined;

            const response = await getAllTransactionsPerLoadingList(containerNumber, formattedStartDate, formattedEndDate);

            setSummaryReport(response.data);

        } catch (error) {
            console.error("Error while downloading transaction reports:", error);
        }
    }

    const handleDownloadPdf = async () => {
        try {
            const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : 'start';
            const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : 'end';
            let fileName = `transactions_per_loading_report_${formattedStartDate}_${formattedEndDate}.pdf`;
            let pdfContent = document.getElementById('element-to-print_transactions_per_loading').innerHTML;

            if(reportView === 'summary'){
                fileName = `transactions_per_loading_summary_report${formattedStartDate}_${formattedEndDate}.pdf`;
                pdfContent = document.getElementById('element-to-print-transaction-per-loading-summary-report').innerHTML;
            }

            await generatePdfReportAndDownload(pdfContent, fileName);
            setDownloading(false);
        } catch (error) {
            console.error('Error in downloading PDF:', error);
        }
    };

    const handleSelectReportView = (view) => {
        setReportView(view);
        if(view === 'summary')
        {
            handleDownloadSummaryReport();
        }
        else 
        {
            setSummaryReport([]);
        }
    }

    useEffect(() => {
        dispatch(setPortalPage('Transactions Per Loading or Container Num. (Details in Billings)'));
    }, [dispatch]);

    return (
        <>
            <Card border="light" className="table-wrapper table-responsive shadow-sm card-wrapper-full-height">
                <Card.Body className="pt-0">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        <div className="d-block mb-4 mb-md-0">
                            <p className="mb-0">Transactions Per Loading or Container Num. (Details in Billings)</p>
                        </div>
                    </div>
                    <div className="table-settings mb-5">
                        <Row className="justify-content-between align-items-center">
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Container Number</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        {...register('containerNumber')}
                                        placeholder="Enter container number"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group id="start-date">
                                    <Form.Label>Start Date</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={handleStartDateChange}
                                        closeOnSelect={true}
                                        renderInput={(props, openCalendar) => (
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faCalendar} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    value={startDate ? moment(startDate).format('MM/DD/YYYY') : ''}
                                                    placeholder="Select date"
                                                    onFocus={openCalendar}
                                                    autoComplete="off"
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    {errors.startDate?.message && (
                                        <small className="invalid-fields">{errors.startDate.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group id="end-date">
                                    <Form.Label>End Date</Form.Label>
                                    <Datetime
                                        timeFormat={false}
                                        onChange={handleEndDateChange}
                                        closeOnSelect={true}
                                        renderInput={(props, openCalendar) => (
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faCalendar} />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    value={endDate ? moment(endDate).format('MM/DD/YYYY') : ''}
                                                    placeholder="Select date"
                                                    onFocus={openCalendar}
                                                    autoComplete="off"
                                                />
                                            </InputGroup>
                                        )}
                                    />
                                    {errors.endDate?.message && (
                                        <small className="invalid-fields">{errors.endDate.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="d-flex justify-content-start mt-4">
                                <Button variant="primary" type="button" onClick={() => handleGenerateReport()}>
                                    Search
                                </Button>
                                <Button className="ms-2" variant="secondary" type="button" onClick={() => resetFilters()}>
                                    Reset
                                </Button>
                                {reportList.length > 0 && (
                                    <Button className="ms-2" variant="primary" type="button" onClick={() => handleDownloadReport()}>
                                        <FontAwesomeIcon icon={faFileDownload} /> {`${downloading ? 'Downloading...' : 'Download Report'}`}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </div>
                    {reportList.length > 0 && (
                        <div className="btn-toolbar mb-2 mb-md-0 result-view">
                            <ButtonGroup>
                                <Button className={`${reportView === 'list' ? 'active' : ''}`} variant="outline-primary" size="sm" onClick={() => handleSelectReportView('list')}>
                                    <FontAwesomeIcon icon={faListAlt} className="me-2" /> List
                                </Button>
                                <Button className={`${reportView === 'summary' ? 'active' : ''}`} variant="outline-primary" size="sm" onClick={() => handleSelectReportView('summary')}>
                                    <FontAwesomeIcon icon={faFileAlt} className="me-2" /> Summary
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}
                    {reportView === 'list' && (
                        <>
                            <Table hover className="billing-table-list align-items-center">
                                <thead>
                                    <tr>
                                        <th className="border-bottom">Container Number</th>
                                        <th className="border-bottom">Client Name</th>
                                        <th className="border-bottom">Sum of CBM</th>
                                        <th className="border-bottom">Gross Amount</th>
                                        <th className="border-bottom">Freight Amount</th>
                                        <th className="border-bottom">Total Billable Amount</th>
                                        <th className="border-bottom">Applied Rate</th>
                                        <th className="border-bottom">Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{loading ? <Skeleton wrapper={SkeletonWrapper} /> : item.containerNumber}</td>
                                            <td>{loading ? <Skeleton wrapper={SkeletonWrapper} /> : item.clientName}</td>
                                            <td>{loading ? <Skeleton wrapper={SkeletonWrapper} /> : formatCurrency(item.sumOfCBM)}</td>
                                            <td>{loading ? <Skeleton wrapper={SkeletonWrapper} /> : formatCurrency(item.grossAmount)}</td>
                                            <td>{loading ? <Skeleton wrapper={SkeletonWrapper} /> : formatCurrency(item.totalFreightAmount)}</td>
                                            <td>{loading ? <Skeleton wrapper={SkeletonWrapper} /> : formatCurrency(item.totalBillableAmount)}</td>
                                            <td>{loading ? <Skeleton wrapper={SkeletonWrapper} /> : formatCurrency(item.appliedRate)}</td>
                                            <td>{loading ? <Skeleton wrapper={SkeletonWrapper} /> : moment(item.createdDate).format('YYYY-MM-DD')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {!loading && (
                                <PortalPagination totalRecords={totalRecords} currentPage={selectedPage} pagingEvent={handlePageEvent} />
                            )}
                        </>
                    )}

                    {reportView === 'summary' && (
                        <>
                            <TransactionPerLoadingSummary reportData={summaryReport} startDateFilter={startDate} endDateFilter={endDate} />
                            <div className='report-element-to-print' id='element-to-print-transaction-per-loading-summary-report'>
                                <TransactionPerLoadingSummaryPreview reportData={summaryReport} startDateFilter={startDate} endDateFilter={endDate} />
                            </div>
                        </>
                    )}
                </Card.Body>
            </Card>
            <div className="report-element-to-print" id="element-to-print_transactions_per_loading">
                <TransactionsPerLoadingPreview startDate={startDate} endDate={endDate} reportList={allReportList} />
            </div>
        </>
    );
};

export default TransactionsPerLoading;

import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import moment from "moment-timezone";
import { formatCurrency } from "../utils/formatter";

const TransactionPerLoadingSummary = (props) => {
  const { reportData, startDateFilter, endDateFilter } = props;

  // Convert start and end date filters to moment objects
  const startMoment = moment(startDateFilter);
  const endMoment = moment(endDateFilter);

  // Generate months based on the range from start to end filters
  const filteredMonths = [];
  let currentMonth = startMoment.clone();
  while (currentMonth.isSameOrBefore(endMoment, "month")) {
    filteredMonths.push({
      month: currentMonth.month() + 1,
      label: currentMonth.format("MMMM"),
    });
    currentMonth.add(1, "month");
  }

  // Extract unique years from the filtered report data
  const years = [...new Set(reportData.map((item) => moment(item.loadingDate).year()))];

  // Group by year and month
  const groupByMonthAndContainer = (list, year, month) => {
    const filteredList = list.filter((item) => {
      const itemDate = moment(item.loadingDate);
      return (
        itemDate.year() === year &&
        itemDate.month() + 1 === month &&
        (!startDateFilter || itemDate.isSameOrAfter(moment(startDateFilter))) &&
        (!endDateFilter || itemDate.isSameOrBefore(moment(endDateFilter)))
      );
    });

    const grouped = [...new Set(filteredList.map((item) => item.containerNumber))].map((containerNumber) => ({
      containerNumber,
    }));

    return {
      hasResult: filteredList.length > 0,
      grouped,
    };
  };

  return (
    <>
      {years.map((year, yearIndex) => (
        <Fragment key={yearIndex}>
          <Table className="main-tbl-summary mt-5">
            {filteredMonths.map((monthObj, index) => {
              const { hasResult, grouped } = groupByMonthAndContainer(reportData, year, monthObj.month);

              return (
                <Fragment key={index}>
                  <thead>
                    <tr>
                      <th className="border-bottom month-indicator">
                        <h5>
                          {monthObj.label} {year}
                        </h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-bottom">
                        {hasResult ? (
                          <Table hover className="billing-table-list align-items-center">
                            <thead>
                              <tr>
                                <th className="border-bottom">Container Number</th>
                                <th className="border-bottom">Total CBM</th>
                                <th className="border-bottom">Total Gross Amount</th>
                                <th className="border-bottom">Total Freight Amount</th>
                                <th className="border-bottom">Total Billable Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {grouped.map((entry, index) => {
                                const filteredItems = reportData.filter(
                                  (item) =>
                                    moment(item.loadingDate).year() === year &&
                                    moment(item.loadingDate).month() + 1 === monthObj.month &&
                                    item.containerNumber === entry.containerNumber
                                );

                                const totalCBM = filteredItems.reduce((sum, item) => sum + parseFloat(item.sumOfCBM), 0).toFixed(2);
                                const totalGrossAmount = filteredItems.reduce((sum, item) => sum + parseFloat(item.grossAmount), 0).toFixed(2);
                                const totalFreightAmount = filteredItems.reduce((sum, item) => sum + parseFloat(item.totalFreightAmount), 0).toFixed(2);
                                const totalBillableAmount = filteredItems.reduce((sum, item) => sum + parseFloat(item.totalBillableAmount), 0).toFixed(2);

                                return (
                                  <tr key={index}>
                                    <td>{entry.containerNumber}</td>
                                    <td>{totalCBM}</td>
                                    <td>{formatCurrency(parseFloat(totalGrossAmount))}</td>
                                    <td>{formatCurrency(parseFloat(totalFreightAmount))}</td>
                                    <td>{formatCurrency(parseFloat(totalBillableAmount))}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        ) : (
                          <div>*** No Records Found ***</div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Fragment>
              );
            })}
          </Table>
        </Fragment>
      ))}
    </>
  );
};

export default TransactionPerLoadingSummary;

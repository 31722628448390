import React, { useState } from 'react';
import { Row, Col } from '@themesberg/react-bootstrap';
import { showOk } from '../components/Alerts';
import { Button, Modal, Form } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import billingApi from '../services/billing-service';

const BillingCancelReason = (props) => {
  const { 
    generatedBillingId,
    cancelEvent,
    refreshList
  } = props;

  const { Header, Dialog, Title, Body, Footer } = Modal;
  const [loading, setLoading] = useState(false);

  const { cancelBilling } = billingApi

  const cancelBillingSchema = Yup.object().shape({
    reason: Yup.string().required('Reason is required'),
  });

  const formOptions = {
    resolver: yupResolver(cancelBillingSchema),
    mode: 'all',
  };

  const { register, handleSubmit, getValues, formState, reset } = useForm(formOptions);

  const { errors } = formState;

  const handleCancelBilling = () => {
    setLoading(true);

    let billingData = new FormData();

    const reason = getValues('reason');

    billingData.append('generatedBillingId', generatedBillingId);
    billingData.append('reason', reason);

    cancelBilling(billingData).then(() => {
        showOk('Billing rejected!');
        reset();
        cancelEvent();
        refreshList();
    });
  }

  return (
    <Modal as={Dialog} backdropClassName="" centered show={true}>
      <Form onSubmit={handleSubmit(handleCancelBilling)}>
        <Header>
          <Title className="h6">Provide reason why you reject this billing</Title>
          <Button variant="close" aria-label="Close" onClick={() => cancelEvent()} />
        </Header>
        <Body>
            <Row className='align-items-start'>
                <Col sm={12} className="mb-3">
                    <Form.Group id="reason">
                        <Form.Label className="light-font">Reason</Form.Label>
                        <Form.Control as="textarea" placeholder='Please add reason' {...register('reason')} rows="3" />
                        {errors.reason?.message && <small className="invalid-fields">{errors.reason.message}</small>}
                    </Form.Group>
                </Col>
            </Row>
        </Body>
        <Footer>
          <Button type="submit" disabled={loading} variant="secondary">
            Submit
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default BillingCancelReason;

import React, { useEffect } from 'react';
import { setPortalPage } from '../actions/blanket';
import { useDispatch } from 'react-redux';
import authApi from '../services/auth-api-service';

function Dashboard() {
  const dispatch = useDispatch();

  const { checkToken } = authApi;

  useEffect(() => {
    checkToken().then(() => {
      dispatch(setPortalPage('Dashboard'));
    });
  }, [dispatch, checkToken]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h2>Streamline your journey with SeaRoad Enterprise Portal</h2>
        </div>
      </div>
    </>
  );
}

export default Dashboard;

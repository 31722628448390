import React, { useState } from 'react';
import { Row, Col } from '@themesberg/react-bootstrap';
import { Button, Modal, Form } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './styles.scss'
import rateCategoryApi from '../services/rate-category-service';
import { showError, showOk } from '../components/Alerts';

const RateCategoryForm = (props) => {
    const { 
        rateCategoryData,
        cancelEdit,
        refreshList
    } = props;

    const { createRateCategory, updateRateCategory } = rateCategoryApi;
    const { Header, Dialog, Title, Body, Footer } = Modal;
    const [loading, setLoading] = useState(false);

    const rateCategorySchema = Yup.object().shape({
        name: Yup.string().required('Category name is required'),
        unit: Yup.string().required('Category unit is required'),
    });

    const formOptions = {
        resolver: yupResolver(rateCategorySchema),
        mode: 'all',
        defaultValues: {
          name: rateCategoryData?.name,
          unit: rateCategoryData?.categoryUnit,
        }
    };

    const { register, handleSubmit, getValues, formState, reset } = useForm(formOptions);

    const { errors } = formState;

    const handleSaveRateCategory = () => {
        setLoading(true);

        const formData = new FormData();
        const name = getValues('name');
        const unit = getValues('unit');

        formData.append('name', name);
        formData.append('rateCategoryUnit', unit);

        if(rateCategoryData){
            formData.append('rateCategoryId', rateCategoryData._id);

            updateRateCategory(formData)
                .then(() => {
                    showOk('Rate category updated');
                    reset();
                    refreshList();
                    cancelEdit();
                    setLoading(false);
                })
                .catch((e) => {
                    showError(e?.data?.error);
                    setLoading(false);
                });
        }
        else
        {
            createRateCategory(formData)
            .then(() => {
                showOk('Rate category saved');
                reset();
                refreshList();
                cancelEdit();
                setLoading(false);
            })
            .catch((e) => {
                showError(e?.data?.error);
                setLoading(false);
            });
        }
    }

    return (
        <Modal as={Dialog} backdropClassName="" centered show={true}>
            <Form onSubmit={handleSubmit(handleSaveRateCategory)}>
                <Header>
                    <Title className="h6">{`${rateCategoryData ? 'Update' : 'Create'}`} - Rate Category</Title>
                    <Button variant="close" aria-label="Close" onClick={() => cancelEdit()} />
                </Header>
                <Body>
                    <Row>
                        <Col md={12} className="mb-4">
                            <Form.Group id="rate-category-name">
                                <Form.Label className="light-font">Category name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Category name"
                                        {...register('name')}
                                    />
                                    {errors.name?.message && (
                                        <small className="invalid-fields">{errors.name.message}</small>
                                    )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mb-4">
                            <Form.Group id="rate-category-unit">
                                <Form.Label className="light-font">Category unit</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="Category unit"
                                        {...register('unit')}
                                    />
                                    {errors.unit?.message && (
                                        <small className="invalid-fields">{errors.unit.message}</small>
                                    )}
                            </Form.Group>
                        </Col>
                    </Row>
                </Body>
                <Footer>
                    <Button type="submit" disabled={loading} variant="secondary">
                        Save
                    </Button>
                </Footer>
            </Form>
        </Modal>
    );
}

export default RateCategoryForm;
import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Table } from '@themesberg/react-bootstrap';
import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { showOk, showError, confirmDeleteDialog } from '../components/Alerts'; // Assuming you have a showConfirm method in Alerts
import { Button, Modal, Form, ButtonGroup } from '@themesberg/react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import productCategoryApi from '../services/product-category-services';
import { hasPermission } from '../auth/UserPermissionTypes';
import { PRODUCT_MANAGER_ADD_PRODUCT_CATEGORY, PRODUCT_MANAGER_DELETE_PRODUCT_CATEGORY, PRODUCT_MANAGER_UPDATE_PRODUCT_CATEGORY } from '../utils/constants';

const ProductCategory = (props) => {

    const {
        cancelProductCategory,
        refreshCategoryList,
        refreshList
    } = props;

    const { createProductCategory, updateProductCategory, deleteProductCategory, getProductCategories } = productCategoryApi;

    const { Header, Dialog, Title, Body, Footer } = Modal;
    const [loading, setLoading] = useState(false);
    const [addCategory, setAddCategory] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [editCategory, setEditCategory] = useState(null);

    const productCategorySchema = Yup.object().shape({
        categoryName: Yup.string().required('Category name is required'),
    });
    
    const formOptions = {
        resolver: yupResolver(productCategorySchema),
        mode: 'all',
    };

    const { register, handleSubmit, getValues, formState, reset, setValue } = useForm(formOptions);

    const { errors } = formState;

    const handleClose = () => {
        cancelProductCategory();
    };

    const handleFetchCategory = useCallback(async () => {
        try {
            const result = await getProductCategories();
            setCategoryList(result);
        } catch (error) {
            console.log(error);
        }
    }, [getProductCategories]);

    const handleSaveCategory = async () => {
        setLoading(true);
    
        const formData = new FormData();
        const categoryName = getValues('categoryName');
        const description = getValues('categoryDescription');
    
        formData.append('name', categoryName);
        formData.append('description', description);
    
        try {
            if (editCategory) {
                // Update existing category
                formData.append('id', editCategory._id);
                await updateProductCategory(formData);
                showOk('Product category updated');
            } else {
                // Create new category
                await createProductCategory(formData);
                showOk('Product category saved');
            }
            reset();
            setEditCategory(null); // Clear the edit category after saving
            setAddCategory(false); // Close the form after saving
            handleFetchCategory();
            refreshCategoryList();
            refreshList();
        } catch (e) {
            showError(e?.data?.error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancelAddCategory = () => {
        reset();
        setAddCategory(!addCategory);
    }

    const handleEditCategory = (category) => {
        setEditCategory(category);
        setValue('categoryName', category.name);
        setValue('categoryDescription', category.description || '');
        setAddCategory(true);
    };

    const handleDeleteCategory = async (category) => {
        var confirmDelete = await confirmDeleteDialog();

        if (confirmDelete) {
            try {
                await deleteProductCategory(category._id);
                showOk('Product category deleted successfully');
                handleFetchCategory(); // Refresh the category list after deletion
            } catch (e) {
                showError(e?.data?.error || "Failed to delete product category");
            }
        }
    };

    useEffect(() => {
        handleFetchCategory();
    }, [handleFetchCategory]);

    return (
        <Modal size="lg" as={Dialog} backdropClassName="" centered show={true}>
            <Header>
                <Title className="h6 me-2">Manage - Product Category</Title>
                {hasPermission(PRODUCT_MANAGER_ADD_PRODUCT_CATEGORY) && (
                    <Button variant="primary" size="sm" onClick={() => {
                        setEditCategory(null);
                        setAddCategory(!addCategory);
                    }}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" /> Add Category
                    </Button>  
                )}
                <Button variant="close" aria-label="Close" onClick={() => handleClose()} />
            </Header>
            <Body>
                {addCategory && (
                    <Form onSubmit={handleSubmit(handleSaveCategory)}>
                        <Row>
                            <Col md={12} className="mb-4">
                                <Form.Group id="product-category-name">
                                    <Form.Label className="light-font">Category name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="categoryName"
                                        placeholder="Category name"
                                        {...register('categoryName')}
                                    />
                                    {errors.categoryName?.message && (
                                        <small className="invalid-fields">{errors.categoryName.message}</small>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="mb-4">
                                <Form.Group id="product-category-description">
                                    <Form.Label className="light-font">Description (Optional) </Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        placeholder='Category short description' 
                                        {...register('categoryDescription')} 
                                        rows="1" 
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="mb-4">
                                <Button type="submit" disabled={loading} variant="secondary" className='me-1'>
                                    Save
                                </Button>
                                <Button variant="primary" className="ms-auto" onClick={() => handleCancelAddCategory()}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
                <Row>
                    <Col md={12} className="mb-4">
                        <h5 className="mb-3 border-bottom border-light mt-3 pb-md-3 mb-md-3">Category Lists</h5>
                        <div className="category-table-container">
                            <Table hover className="billing-table-listr">
                            <thead>
                                <tr>
                                <th className="border-bottom">Name</th>
                                <th className="border-bottom">Description</th>
                                <th className="border-bottom">Linked Products</th>
                                <th className="border-bottom"></th>
                                </tr>
                            </thead>
                                <tbody>
                                    {categoryList && categoryList.map((item, i) => (
                                    <tr key={i}>
                                        <td><span className="fw-normal">{item.name || ''}</span></td>
                                        <td>
                                        <span 
                                            className="fw-normal" 
                                            title={item.description}
                                        >
                                            {item.description 
                                            ? item.description.length > 20 
                                                ? `${item.description.slice(0, 20)}...`
                                                : item.description 
                                            : ''}
                                        </span>
                                        </td>
                                        <td><span className="fw-normal">{item.productCount || '0'}</span></td>
                                        <td>
                                        <ButtonGroup>
                                            {hasPermission(PRODUCT_MANAGER_UPDATE_PRODUCT_CATEGORY) && (
                                            <Button variant="primary" size="sm" title='Edit Category' onClick={() => handleEditCategory(item)}>
                                                <FontAwesomeIcon icon={faEdit} className="me-2" />
                                            </Button>
                                            )}
                                            {hasPermission(PRODUCT_MANAGER_DELETE_PRODUCT_CATEGORY) && item.productCount === 0 && (
                                            <Button variant="danger" size="sm" title='Delete Category' onClick={() => handleDeleteCategory(item)}>
                                                <FontAwesomeIcon icon={faTrash} className="me-2" />
                                            </Button>  
                                            )}
                                        </ButtonGroup>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Body>
            <Footer>
                <Button variant="primary" className="ms-auto" onClick={handleClose}>
                    Close
                </Button>
            </Footer>
        </Modal>
    )   
}

export default ProductCategory;

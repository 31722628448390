import React, { useState } from 'react';
import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { showOk, showError } from '../components/Alerts';
import clientApi from '../services/client-service';
import ClientAgreements from './clientagreements';
import ClientChannels from './clientchannel';
import ClientTransactions from './clienttransactions';
import ClientUploadDocument from './clientuploaddocument';
import { hasPermission } from '../auth/UserPermissionTypes';
import { CLIENT_MANAGER_MANAGE_AGREEMENTS, CLIENT_MANAGER_MANAGE_NOTIFICATION_CHANNEL, CLIENT_MANAGER_VIEW_TRANSACTION_HISTORY } from '../utils/constants';
const ClientForm = (props) => {
  const { update, create } = clientApi;
  const { isEditMode, client, cancelEdit, clientAgreements, refreshDocuments } = props;
  const [loading, setLoading] = useState(false);
  const [sameWithBillingAddress, setSameWithBillingAddress] = 
    useState(client?.clientAddressData?.isShippingTheSameWithBillingAddress);
  const [showAddClientDocument, setShowAddClientDocument] = useState(false);
  const [clientDocumentData, setClientDocumentData] = useState(false);

  const formSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .required('Email is required'),
    displayName: Yup.string().required('Display name is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    mobileNumber: Yup.string().required('Mobile number is required'),
    billingAddress1: Yup.string().required('Billing address 1 is required'),
    billingCity: Yup.string().required('Billing city is required'),
    billingProvince: Yup.string().required('Billing province is required'),
    billingZipCode: Yup.string().required('Billing postal code is required'),
    billingCountry: Yup.string().required('Billing country is required'),
  });

  const withShippingValidationformSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .required('Email is required'),
    displayName: Yup.string().required('Display name is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    mobileNumber: Yup.string().required('Mobile number is required'),
    billingAddress1: Yup.string().required('Billing address 1 is required'),
    billingCity: Yup.string().required('Billing city is required'),
    billingProvince: Yup.string().required('Billing province is required'),
    billingZipCode: Yup.string().required('Billing postal code is required'),
    billingCountry: Yup.string().required('Billing country is required'),
    shippingAddress1: Yup.string().required('Shipping address 1 is required'),
    shippingCity: Yup.string().required('Shipping city is required'),
    shippingProvince: Yup.string().required('Shipping province is required'),
    shippingZipCode: Yup.string().required('Shipping postal code is required'),
    shippingCountry: Yup.string().required('Shipping country is required'),
  });

  const formOptions = {
    resolver: sameWithBillingAddress ? yupResolver(formSchema) : yupResolver(withShippingValidationformSchema),
    mode: 'all',
    defaultValues: {
      referenceNumber: client?.referenceNumber,
      firstName: client?.firstName,
      lastName: client?.lastName,
      displayName: client?.displayName,
      companyName: client?.companyName,
      email: client?.email,
      phoneNumber: client?.phoneNumber,
      mobileNumber: client?.mobileNumber,
      faxNumber: client?.faxNumber,
      website: client?.website,
      others: client?.others,
      description: client?.description,
      notes: client?.notes,
      billingAddress1: client?.clientAddressData?.billingAddress1,
      billingAddress2: client?.clientAddressData?.billingAddress2,
      billingCity: client?.clientAddressData?.billingCity,
      billingProvince: client?.clientAddressData?.billingProvince,
      billingZipCode: client?.clientAddressData?.billingZipCode,
      billingCountry: client?.clientAddressData?.billingCountry,
      shippingAddress1: client?.clientAddressData?.shippingAddress1,
      shippingAddress2: client?.clientAddressData?.shippingAddress2,
      shippingCity: client?.clientAddressData?.shippingCity,
      shippingProvince: client?.clientAddressData?.shippingProvince,
      shippingZipCode: client?.clientAddressData?.shippingZipCode,
      shippingCountry: client?.clientAddressData?.shippingCountry,
    },
  };

  const { register, handleSubmit, getValues, formState, reset, setValue } = useForm(formOptions);

  const { errors } = formState;

  const onCheckSameBillingAddress = (e) => {
    setSameWithBillingAddress(e.target.checked);
  }

  const onSubmit = () => {
    setLoading(true);

    if(sameWithBillingAddress){
      const billingAddress1 = getValues("billingAddress1");
      const billingAddress2 = getValues("billingAddress2");
      const billingCity = getValues("billingCity");
      const billingProvince = getValues("billingProvince");
      const billingZipCode = getValues("billingZipCode");
      const billingCountry = getValues("billingCountry");

      setValue("shippingAddress1", billingAddress1);
      setValue("shippingAddress2", billingAddress2);
      setValue("shippingCity", billingCity);
      setValue("shippingProvince", billingProvince);
      setValue("shippingZipCode", billingZipCode);
      setValue("shippingCountry", billingCountry);
    }

    let clientData = new FormData();
    var formValues = getValues();

    for (const key in formValues) {
      if (key === 'field') {
        let formValue = formValues[key][1];
        clientData.append(key, formValue);
      } else {
        let formValue = formValues[key];

        clientData.append(key, formValue);
      }
    }

    clientData.append('isShippingTheSameWithBillingAddress', sameWithBillingAddress);

    if (client) {
      clientData.append('clientId', client._id);
      update(clientData)
      .then((data) => {
        if (data.error) {
          showError(data.error);
          setLoading(false);
        } else {
          showOk('Client information saved');
          cancelEdit();
          setLoading(false);
        }
      })
      .catch((e) => {
        showError(e?.data?.error);
        setLoading(false);
      });
    } else {
      create(clientData)
      .then((data) => {
        if (data.error) {
          showError(data.error);
          setLoading(false);
        } else {
          showOk('Client information saved');
          setLoading(false);
          cancelEdit();
          reset();
        }
      })
      .catch((e) => {
        showError(e?.data?.error);
        setLoading(false);
      });
    }
  };

  const handleEditDocument = (documentData) => {
    setClientDocumentData(documentData);
    setShowAddClientDocument(true);
  }

  const generateLabel = (label, fieldName) => {
    const isRequired = formSchema.fields[fieldName] || withShippingValidationformSchema.fields[fieldName];
    return (
      <Form.Label className="light-font">
        {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
      </Form.Label>
    );
  };

  return (
    <>
      {showAddClientDocument && (
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <ClientUploadDocument 
            clientDocumentData={clientDocumentData}
            clientId={client._id} 
            showUploadDocument={setShowAddClientDocument} 
            isEditMode={isEditMode}
            refreshDocuments={refreshDocuments}
            setClientDocumentData={setClientDocumentData}/>
        </div>
      )}
    
      <Card border="dark" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <h3 className="mb-3 border-bottom border-light mt-3 pb-md-3 mb-md-3">Client Information</h3>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className='align-items-start'>
              <Col md={4} className="mb-3">
                <Form.Group id="referenceNumber">
                  <Form.Label className="light-font">Client Reference No.</Form.Label>
                  {isEditMode ? (
                    <Form.Control 
                      type="text" 
                      name="referenceNumber" 
                      placeholder="Client Reference"
                      readOnly={isEditMode && client?.referenceNumber}
                      {...register('referenceNumber')} />
                  ) : (
                    <p className="mb-0 bold-700">{client?.referenceNumber || '--'}</p>
                  )}
                  {errors.referenceNumber?.message && <small className="invalid-fields">{errors.referenceNumber.message}</small>}
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="firstName">
                  {generateLabel('First Name', 'firstName')}
                  {isEditMode ? (
                    <Form.Control type="text" name="firstName" placeholder="First name" {...register('firstName')} />
                  ) : (
                    <p className="mb-0 bold-700">{client.firstName || ''}</p>
                  )}
                  {errors.firstName?.message && <small className="invalid-fields">{errors.firstName.message}</small>}
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="lastName">
                  {generateLabel('Last Name', 'lastName')}
                  {isEditMode ? (
                    <Form.Control type="text" name="last" placeholder="Last name" {...register('lastName')} />
                  ) : (
                    <p className="mb-0 bold-700">{client.lastName || ''}</p>
                  )}
                  {errors.lastName?.message && <small className="invalid-fields">{errors.lastName.message}</small>}
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-start'>
              <Col md={4} className="mb-3">
                <Form.Group id="displayName">
                  {generateLabel('Client Display Name', 'displayName')}
                  {isEditMode ? (
                    <Form.Control type="text" name="displayName" placeholder="Client Display Name" {...register('displayName')} />
                  ) : (
                    <p className="mb-0 bold-700">{client.displayName || '--'}</p>
                  )}
                  {errors.displayName?.message && <small className="invalid-fields">{errors.displayName.message}</small>}
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="companyName">
                  <Form.Label className="light-font">Company Name</Form.Label>
                  {isEditMode ? (
                    <Form.Control type="text" name="companyName" placeholder="Company name" {...register('companyName')} />
                  ) : (
                    <p className="mb-0 bold-700">{client.companyName || ''}</p>
                  )}
                  {errors.companyName?.message && <small className="invalid-fields">{errors.companyName.message}</small>}
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="email">
                  {generateLabel('Email (separate emails with semicolon ;)', 'email')}
                  {isEditMode ? (
                    <Form.Control type="text" name="email" placeholder="example@email.com;example2@email.com" {...register('email')} />
                  ) : (
                    <p className="mb-0 bold-700">{client.email || ''}</p>
                  )}
                  {errors.email?.message && <small className="invalid-fields">{errors.email.message}</small>}
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-start'>
              <Col md={4} className="mb-3">
                  <Form.Group id="phoneNumber">
                    {generateLabel('Phone Number', 'phoneNumber')}
                    {isEditMode ? (
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone number"
                        {...register('phoneNumber')}
                      />
                    ) : (
                      <p className="mb-0 bold-700">{client.phoneNumber || ''}</p>
                    )}
                    {errors.phoneNumber?.message && (
                      <small className="invalid-fields">{errors.phoneNumber.message}</small>
                    )}
                  </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                  <Form.Group id="mobileNumber">
                    {generateLabel('Mobile Number', 'mobileNumber')}
                    {isEditMode ? (
                      <Form.Control
                        type="text"
                        name="mobileNumber"
                        placeholder="Mobile number"
                        {...register('mobileNumber')}
                      />
                    ) : (
                      <p className="mb-0 bold-700">{client.mobileNumber || ''}</p>
                    )}
                    {errors.mobileNumber?.message && (
                      <small className="invalid-fields">{errors.mobileNumber.message}</small>
                    )}
                  </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                  <Form.Group id="faxNumber">
                    <Form.Label className="light-font">Fax Number</Form.Label>
                    {isEditMode ? (
                      <Form.Control
                        type="text"
                        name="faxNumber"
                        placeholder="Fax number"
                        {...register('faxNumber')}
                      />
                    ) : (
                      <p className="mb-0 bold-700">{client.faxNumber || ''}</p>
                    )}
                    {errors.faxNumber?.message && (
                      <small className="invalid-fields">{errors.faxNumber.message}</small>
                    )}
                  </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-start'>
              <Col md={4} className="mb-3">
                  <Form.Group id="website">
                    <Form.Label className="light-font">Website</Form.Label>
                    {isEditMode ? (
                      <Form.Control
                        type="text"
                        name="website"
                        placeholder="Website"
                        {...register('website')}
                      />
                    ) : (
                      <p className="mb-0 bold-700">{client.website || ''}</p>
                    )}
                    {errors.website?.message && (
                      <small className="invalid-fields">{errors.website.message}</small>
                    )}
                  </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                  <Form.Group id="others">
                    <Form.Label className="light-font">Others</Form.Label>
                    {isEditMode ? (
                      <Form.Control
                        type="text"
                        name="others"
                        placeholder="Others"
                        {...register('others')}
                      />
                    ) : (
                      <p className="mb-0 bold-700">{client.others || ''}</p>
                    )}
                    {errors.others?.message && (
                      <small className="invalid-fields">{errors.others.message}</small>
                    )}
                  </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="description">
                  <Form.Label className="light-font">Short Descriptions</Form.Label>
                  {isEditMode ? (
                    <Form.Control as="textarea" placeholder='Client short description or indication' {...register('description')} rows="1" />
                  ) : (
                    <p className="mb-0 bold-700">{client.description || ''}</p>
                  )}
                  {errors.description?.message && <small className="invalid-fields">{errors.description.message}</small>}
                </Form.Group>
              </Col>
            </Row>
            {hasPermission(CLIENT_MANAGER_MANAGE_AGREEMENTS) && client && (
              <>
                <h3 className="mb-3 border-bottom border-light mt-3 pb-md-3 mb-md-3">Contracts & Agreements</h3>
                <Row className='align-items-start'>
                  <Col sm={6} className="mb-3">
                    <ClientAgreements 
                      clientId={client._id} 
                      isEditMode={isEditMode} 
                      showUploadDocument={setShowAddClientDocument} 
                      clientAgreements={clientAgreements} 
                      refreshDocuments={refreshDocuments} 
                      handleEditDocument={handleEditDocument}/>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Group id="notes">
                      <Form.Label className="light-font">Notes</Form.Label>
                      {isEditMode ? (
                        <Form.Control as="textarea" placeholder='Add notes' {...register('notes')} rows="3" />
                      ) : (
                        <p className="mb-0 bold-700">{client.notes || ''}</p>
                      )}
                      {errors.notes?.message && <small className="invalid-fields">{errors.notes.message}</small>}
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            {hasPermission(CLIENT_MANAGER_MANAGE_NOTIFICATION_CHANNEL) && client && (
              <>
                <h3 className="mb-3 border-bottom border-light mt-3 pb-md-3 mb-md-3">Notification Channel</h3>
                <Row className='align-items-start'>
                  <Col sm={10} className="mb-3">
                      <ClientChannels 
                        clientId={client?._id} 
                        notificationChannelId={client?.notificationChannelData?._id}
                        isEditMode={isEditMode}/>
                  </Col>
                </Row>
              </>
            )}
            <h3 className="mb-3 border-bottom border-light mt-3 pb-md-3 mb-md-3">Billing Address</h3>
            <Row className='align-items-start'>
              <Col sm={6} className="mb-3">
                <Form.Group id="billingAddress1">
                  {generateLabel('Address 1', 'billingAddress1')}
                  {isEditMode ? (
                    <Form.Control
                      type="text"
                      name="billingAddress1"
                      placeholder="Address 1"
                      {...register('billingAddress1')}
                    />
                  ) : (
                    <p className="mb-0 bold-700">{client?.clientAddressData?.billingAddress1 || ''}</p>
                  )}
                  {errors.billingAddress1?.message && (
                    <small className="invalid-fields">{errors.billingAddress1.message}</small>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6} className="mb-3">
                <Form.Group id="billingAddress2">
                  <Form.Label className="light-font">Address 2</Form.Label>
                  {isEditMode ? (
                    <Form.Control type="text" name="billingAddress2" placeholder="Address 2" {...register('billingAddress2')} />
                  ) : (
                    <p className="mb-0 bold-700">{client?.clientAddressData?.billingAddress2 || ''}</p>
                  )}
                  {errors.billingAddress2?.message && (
                    <small className="invalid-fields">{errors.billingAddress2.message}</small>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-start'>
              <Col sm={6} className="mb-3">
                <Form.Group id="billingCity">
                  {generateLabel('City', 'billingCity')}
                  {isEditMode ? (
                    <Form.Control
                      type="text"
                      name="billingCity"
                      placeholder="City"
                      {...register('billingCity')}
                    />
                  ) : (
                    <p className="mb-0 bold-700">{client?.clientAddressData?.billingCity || ''}</p>
                  )}
                  {errors.billingCity?.message && (
                    <small className="invalid-fields">{errors.billingCity.message}</small>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6} className="mb-3">
                <Form.Group id="billingProvince">
                  {generateLabel('Province', 'billingProvince')}
                  {isEditMode ? (
                    <Form.Control type="text" name="billingProvince" placeholder="Province" {...register('billingProvince')} />
                  ) : (
                    <p className="mb-0 bold-700">{client?.clientAddressData?.billingProvince || ''}</p>
                  )}
                  {errors.billingProvince?.message && (
                    <small className="invalid-fields">{errors.billingProvince.message}</small>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group id="billingZipCode">
                  {generateLabel('Zip Code', 'billingZipCode')}
                  {isEditMode ? (
                    <Form.Control
                      type="number"
                      name="billingZipCode"
                      placeholder="Zip Code"
                      {...register('billingZipCode')}
                    />
                  ) : (
                    <p className="mb-0 bold-700">{client?.clientAddressData?.billingZipCode || ''}</p>
                  )}
                  {errors.billingZipCode?.message && (
                    <small className="invalid-fields">{errors.billingZipCode.message}</small>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6} className="mb-3">
                <Form.Group id="billingCountry">
                  {generateLabel('Country', 'billingCountry')}
                  {isEditMode ? (
                    <Form.Control
                      type="text"
                      name="billingCountry"
                      placeholder="County"
                      {...register('billingCountry')}
                    />
                  ) : (
                    <p className="mb-0 bold-700">{client?.clientAddressData?.billingCountry || ''}</p>
                  )}
                  {errors.billingCountry?.message && (
                    <small className="invalid-fields">{errors.billingCountry.message}</small>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <h3 className="mb-3 border-bottom border-light mt-3 pb-md-3 mb-md-3">Shipping Address</h3>
            <Row>
              <Col md={6} className="mb-2">
                {isEditMode ? (
                    <Form.Group id="same-billing-address">
                      <Form.Check
                        label="The same as billing address"
                        defaultChecked={sameWithBillingAddress}
                        id="sameBillingAddressCheckBox"
                        htmlFor="sameBillingAddressCheckBox"
                        onChange={(e) => onCheckSameBillingAddress(e)}
                        disabled={!isEditMode}
                      />
                    </Form.Group>
                ) : (
                  <>
                    {sameWithBillingAddress && (
                      <h6 className="fw-normal text-gray">
                        <FontAwesomeIcon icon={faCheckCircle} className="icon icon-xs text-success w-20 me-1" />
                        The same as billing address
                      </h6>
                    )}
                  </>
                )}
              </Col>
            </Row>
            {!sameWithBillingAddress && (
              <>
                <Row className='align-items-start'>
                  <Col sm={6} className="mb-3">
                    <Form.Group id="shippingAddress1">
                      {generateLabel('Address 1', 'shippingAddress1')}
                      {isEditMode ? (
                        <Form.Control
                          type="text"
                          name="shippingAddress1"
                          placeholder="Address 1"
                          {...register('shippingAddress1')}
                        />
                      ) : (
                        <p className="mb-0 bold-700">{client?.clientAddressData?.shippingAddress1 || ''}</p>
                      )}
                      {errors.shippingAddress1?.message && (
                        <small className="invalid-fields">{errors.shippingAddress1.message}</small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Group id="shippingAddress2">
                      <Form.Label className="light-font">Address 2</Form.Label>
                      {isEditMode ? (
                        <Form.Control type="text" name="shippingAddress2" placeholder="Address 2" {...register('shippingAddress2')} />
                      ) : (
                        <p className="mb-0 bold-700">{client?.clientAddressData?.shippingAddress2 || ''}</p>
                      )}
                      {errors.shippingAddress2?.message && (
                        <small className="invalid-fields">{errors.shippingAddress2.message}</small>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='align-items-start'>
                  <Col sm={6} className="mb-3">
                    <Form.Group id="shippingCity">
                      {generateLabel('City', 'shippingCity')}
                      {isEditMode ? (
                        <Form.Control
                          type="text"
                          name="shippingCity"
                          placeholder="City"
                          {...register('shippingCity')}
                        />
                      ) : (
                        <p className="mb-0 bold-700">{client?.clientAddressData?.shippingCity || ''}</p>
                      )}
                      {errors.shippingCity?.message && (
                        <small className="invalid-fields">{errors.shippingCity.message}</small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Group id="shippingProvince">
                      {generateLabel('Province', 'shippingProvince')}
                      {isEditMode ? (
                        <Form.Control type="text" name="shippingProvince" placeholder="Province" {...register('shippingProvince')} />
                      ) : (
                        <p className="mb-0 bold-700">{client?.clientAddressData?.shippingProvince || ''}</p>
                      )}
                      {errors.shippingProvince?.message && (
                        <small className="invalid-fields">{errors.shippingProvince.message}</small>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group id="shippingZipCode">
                      {generateLabel('Zip Code', 'shippingZipCode')}
                      {isEditMode ? (
                        <Form.Control
                          type="number"
                          name="shippingZipCode"
                          placeholder="Zip Code"
                          {...register('shippingZipCode')}
                        />
                      ) : (
                        <p className="mb-0 bold-700">{client?.clientAddressData?.shippingZipCode || ''}</p>
                      )}
                      {errors.shippingZipCode?.message && (
                        <small className="invalid-fields">{errors.shippingZipCode.message}</small>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Group id="shippingCountry">
                      {generateLabel('Country', 'shippingCountry')}
                      {isEditMode ? (
                        <Form.Control
                          type="text"
                          name="shippingCountry"
                          placeholder="County"
                          {...register('shippingCountry')}
                        />
                      ) : (
                        <p className="mb-0 bold-700">{client?.clientAddressData?.shippingCountry || ''}</p>
                      )}
                      {errors.shippingCountry?.message && (
                        <small className="invalid-fields">{errors.shippingCountry.message}</small>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            {client && hasPermission(CLIENT_MANAGER_VIEW_TRANSACTION_HISTORY) && (
              <>
                <h3 className="mb-3 border-bottom border-light mt-3 pb-md-3 mb-md-3">Transactions & History</h3>
                <Row>
                  <Col sm={12}>
                      <ClientTransactions clientId={client._id}/>
                  </Col>
                </Row>
              </>
            )}
            {isEditMode && (
              <div className="mt-3">
                <Button variant="primary" type="submit" disabled={loading} className="me-1">
                  Save
                </Button>
                <Button variant="primary" type="button" disabled={loading} onClick={() => cancelEdit(false)}>
                  Cancel
                </Button>
              </div>
            )}

            {!isEditMode && (
              <Button variant="primary" type="button" disabled={loading} onClick={() => cancelEdit(false)}>
                Cancel
              </Button>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ClientForm;
